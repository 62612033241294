<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { Api } from "../../../helpers";
import Swal from "sweetalert2";
import { Erreur } from "../../../helpers/error";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";
import quizCard from "../../../components/widgets/card/quizCard.vue";
export default {
  props: {},
  page: {
    title: "Blog List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcessVue,
    quizCard,
  },
  data() {
    return {
      titre: "",
      progress: 0,
      process: false,
      spinner:false,
      allQuiz: [],
      isAuthError: false,
      authError: "",
      quizElement: {
        name: "",
        indication: "",
        price: 0,
        isFree: false,
        isMultiChoice: false,
        isMultiAttent: false,
        startDate: "",
        endDate: "",
        taux: 0,
      },
      addModal: false,
      secondModal: false,
      title: "Liste des compétitions",
      items: [],
    };
  },
  methods: {
    showAddModal() {
      this.addModal = true;
    },
    imgVideoSelect(event) {
      this.imgSelect = event.target.files[0]; // Get the selected image or video
      console.log(this.imgSelect);
    },

    imgVideoSelectPaysage(event) {
      this.imgPaysage = event.target.files[0];
      console.log(this.imgPaysage);
    },

    imgSponsor(event) {
      this.sponsorLogo = event.target.files[0];
      console.log(this.sponsorLogo);
    },
    createAward() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      // if (
      // 	!this.award.description ||
      // 	!this.award.endDate ||
      // 	!this.award.name ||
      // 	!this.award.startDate ||
      // 	!this.award.votePrice ||
      // 	!this.imgPaysage ||
      // 	!this.imgSelect
      // ) {

      // 	this.isAuthError = true
      // 	this.authError = "Veuillez remplir tous les champs"

      // } else {
      console.log(this.quizElement);
      this.process = true;
      this.addModal = false;
      this.titre = "Création du quiz";
      Api.postFormData(
        `/games/rest/quizz/create/${this.$route.params.id}`,
        {
          imagePaysage: this.imgPaysage,
          imagePortrait: this.imgSelect,
          logoSponsor: this.sponsorLogo,
          name: this.quizElement.name,
          price: this.quizElement.price,
          isFree: this.quizElement.isFree,
          isMultiChoice: this.quizElement.isMultiAttent,
          isMultiAttent: this.quizElement.isMultiAttent,
          startDate: this.quizElement.startDate,
          endDate: this.quizElement.endDate,
          indication: "1",
          taux: this.quizElement.taux,
        },
        onUploadProgress
      )
        .then((response) => {
          this.process = false;
          Swal.fire("Succes!", "Votre quiz a été créé avec succes", "success");
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
      // }
    },
  },

  mounted() {
    // this.process = true
    this.titre = "Chargement des compétitions";
    this.spinner = true
    Api.get("/games/api/quizz/all")
      .then((res) => {
        this.process = false;
        this.allQuiz = res.data;
    this.spinner = false
        console.log(res);
      })
      .catch((error) => {
        this.process = false;
    this.spinner = false
        console.log(error);
        Erreur.gestionErreur(error.message);
      });

    Api.get(`/games/rest/category/${this.$route.params.id}`)
      .then((res) => {
        this.process = false;
        console.log(res);
        this.items.push(
          {
            text: "Quiz",
            href: "/games/quizCategorie",
          },
          {
            text: res.data.name,
            active: true,
          }
        );

        localStorage.setItem("chemin", JSON.stringify(this.items));
      })
      .catch((error) => {
        this.process = false;
        console.log(error);
        Erreur.gestionErreur(error.message);
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProcessVue
      :title="titre"
      :progress="progress"
      :visible="process"
    ></loaderProcessVue>
    <div class="row justify-content-end">
      <div class="col-lg-10" style="margin-top: 10px">
        <div
          @click="showAddModal"
          class="btn c2play-primary"
          style="position: relative; left: 83.5%"
          to="/blog/createArticle"
        >
          <span style="color: #fff"
            ><i class="fas fa-plus font-size-18"></i> {{ $t("addButton") }} un
            quiz</span
          >
        </div>
      </div>
    </div>

    <br />

    <div class="row">
      <quizCard
        v-for="quiz in allQuiz.slice().reverse()"
        :key="quiz.id"
        :quiz="quiz"
      ></quizCard>
      <div style="text-align: center; font-size: 3em" v-if="spinner == true">
        <b-spinner variant="success" role="status"></b-spinner>
        <span v-if="spinner == false && allQuiz.length < 1"
          >Aucun quiz ajouté</span
        >
      </div>
    </div>

    <b-modal
      v-model="addModal"
      id="modal-xl"
      size="xl"
      title="Nouveau quiz"
      title-class="font-18"
      hide-footer
    >
      <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>

        <form>
          <div class="form-group row mb-2">
            <div class="col-lg-6">
              <label for="projectname" class="col-form-label">{{
                $t("pages.videos.formulaire.titre.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <input
                id="projectname"
                name="projectname"
                v-model="quizElement.name"
                type="text"
                class="form-control"
                placeholder="Entrez le nom du quiz"
              />
            </div>

            <div class="form-group col-lg-6 mb-3">
              <label class="col-form-label">Prix</label>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  v-model="quizElement.price"
                  name="projectname"
                  type="text"
                  class="form-control"
                  placeholder="Entrez le prix du quiz"
                />
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label">
                Date de début</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  v-model="quizElement.startDate"
                  name="projectname"
                  type="datetime-local"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                />
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label">Date de fin</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  v-model="quizElement.endDate"
                  name="projectname"
                  type="datetime-local"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                />
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >{{ $t("pages.videos.formulaire.files.image") }} portrait</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="file"
                  @change="imgVideoSelect($event)"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >Image paysage</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="file"
                  @change="imgVideoSelectPaysage($event)"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >Logo sponsors</label
              >
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="file"
                  @change="imgSponsor($event)"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="col-lg-6">
              <label class="col-form-label">Sélectionnez un pourcentage de validation de quiz</label>
              <span style="color: red; font-size: 1.2em">*</span>
              <div class="form-control">
                <select
                  class="tags-input_text"
                  v-model="quizElement.taux"
                >
                  <option value="50"> 50 % </option>
                  <option value="60"> 60 % </option>
                  <option value="70"> 70 % </option>
                  <option value="80"> 80 % </option>
                  <option value="90"> 90 % </option>
                  <option value="100"> 100 % </option>
                </select>
                <!-- <input type="text" :placeholder="$t('pages.videos.formulaire.tags.placeholder')" @keydown="addTags" @keydown.delete="removeLastTag" class="tags-input_text"> -->
              </div>
            </div>
          </div>

          <div class="row mb-2">

            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label">
                Est-il gratuit</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <div class="display">
                  <div style="margin-right: 20px">
                    <b-form-radio
                      v-model="quizElement.isFree"
                      name="some-radios1"
                      value="true"
                      >Oui</b-form-radio
                    >
                  </div>
                  <div>
                    <b-form-radio
                      v-model="quizElement.isFree"
                      name="some-radios1"
                      value="false"
                      >Non</b-form-radio
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >Peut-on tenter plusieurs fois candidats</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <div class="display">
                  <div style="margin-right: 20px">
                    <b-form-radio
                      v-model="quizElement.isMultiAttent"
                      name="some-radios3"
                      value="true"
                      >Oui</b-form-radio
                    >
                  </div>
                  <div>
                    <b-form-radio
                      v-model="quizElement.isMultiAttent"
                      name="some-radios3"
                      value="false"
                      >Non</b-form-radio
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="row">
          <div class="col-lg-12" style="margin-top: 10px">
            <button
              class="btn c2play-primary"
              @click="createAward"
              style="position: relative; left: 45%"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
